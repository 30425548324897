import request, { ResponsePageableData } from '@/libs/request'

/** 房间楼号列表 */
export const getBuildingList = (data?) => request<ResponsePageableData>({ url: '/adminapi/Building/getBuildingList', data })

/** 房间楼号新增/编辑 */
export const editBuilding = (data?) => request<ResponsePageableData>({ url: '/adminapi/Building/editBuilding', data })

/** 房间楼号删除 */
export const delBuilding = (data?) => request<ResponsePageableData>({ url: '/adminapi/Building/delBuilding', data })
